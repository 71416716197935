import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "enableHR API"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`High-Level Concepts`}</h1>
    <p>{`Before diving in the capabilities of the enableHR API, it is important to understand the basic concepts used in the enableHR product.`}</p>
    <p>{`When an organisation signs up to enableHR, an enableHR Account is created. Within this account, worker records are organised in branches, which are either a legal entity or a department.`}</p>
    <p>{`Each worker record has its own set of data fields. Some of these fields are identical across record types (first name for instance), some are restricted to a specific type (start date for instance). enableHR has 4 types of records: `}<em parentName="p"><strong parentName="em">{`Candidates`}</strong></em>{`, `}<em parentName="p"><strong parentName="em">{`Employees`}</strong></em>{`, `}<em parentName="p"><strong parentName="em">{`Volunteers`}</strong></em>{` and `}<em parentName="p"><strong parentName="em">{`Contractors`}</strong></em>{`.`}</p>
    <h1>{`enableHR API`}</h1>
    <p>{`The enableHR API exposes Account information, Branch information, Candidate information and Employee information. It also provides some “actions” that can be executed on Candidates and Employees. Volunteers and Contractors will come at a later stage of development.`}</p>
    <p>{`In order to access this information, you need to have the appropriate rights. This is done via our Authentication and Authorisation Server. Our Client Success team will help you with it.`}</p>
    <h4>{`Candidates:`}</h4>
    <ul>
      <li parentName="ul">{`Actions:`}
        <ol parentName="li">
          <li parentName="ol">{`List existing Candidates`}</li>
          <li parentName="ol">{`Create, retrieve and update a Candidate`}</li>
          <li parentName="ol">{`Merge two Candidates together`}</li>
          <li parentName="ol">{`Convert a Candidate to an Employee`}</li>
        </ol>
      </li>
    </ul>
    <ul>
      <li parentName="ul">{`Fields:`}
        <ol parentName="li">
          <li parentName="ol">{`Personal information (excluding custom fields)`}</li>
        </ol>
      </li>
    </ul>
    <h4>{`Employees:`}</h4>
    <ul>
      <li parentName="ul">{`Actions:`}
        <ol parentName="li">
          <li parentName="ol">{`List existing Employees`}</li>
          <li parentName="ol">{`Create, retrieve and update an Employee`}</li>
          <li parentName="ol">{`Transfer an Employee to another branch`}</li>
          <li parentName="ol">{`Terminate an Employee`}</li>
        </ol>
      </li>
    </ul>
    <ul>
      <li parentName="ul">{`Fields:`}
        <ol parentName="li">
          <li parentName="ol">{`Personal information (excluding custom fields)`}</li>
          <li parentName="ol">{`Bank accounts (Australia and New Zealand)`}</li>
          <li parentName="ol">{`Tax details (Australia and New Zealand)`}</li>
          <li parentName="ol">{`Superannuation (Kiwi Saver in New Zealand)`}</li>
          <li parentName="ol">{`Leave balances`}</li>
          <li parentName="ol">{`Training and Qualification (competencies)`}</li>
        </ol>
      </li>
    </ul>
    <h4>{`Coming next:`}</h4>
    <ul>
      <li parentName="ul">{`Employees: List/Retrieve/Update Custom Fields of an employee`}</li>
      <li parentName="ul">{`Employees: List/Retrieve/Add/Update/Delete roles and groups`}</li>
      <li parentName="ul">{`Employees: List/Retrieve/Add/Update/Delete competencies`}</li>
      <li parentName="ul">{`Employees: Manage roles and competencies for an employee`}</li>
      <li parentName="ul">{`Employees: Create a new employee - Adding Emergency Contact`}</li>
      <li parentName="ul">{`Employees: Update an existing employee - Adding Emergency Contact`}</li>
      <li parentName="ul">{`Employees: Retrieve details of an employee - Adding Emergency Contact`}</li>
      <li parentName="ul">{`Employees: List & retrieve notes for an employee`}</li>
      <li parentName="ul">{`Employees: Add a note to an employee`}</li>
      <li parentName="ul">{`Employees: Check for duplicate employee records`}</li>
      <li parentName="ul">{`Employees: Merge 2 employees`}</li>
      <li parentName="ul">{`Employees: Multi-branch linking of an employee`}</li>
      <li parentName="ul">{`Employees: List & retrieve notes for a candidate`}</li>
      <li parentName="ul">{`Employees: Add a note to a candidate`}</li>
      <li parentName="ul">{`Employees: Transfer to new branch (new copy)`}</li>
      <li parentName="ul">{`Employees: Transfer to new branch (move no trace)`}</li>
      <li parentName="ul">{`Employees: Delete an employee`}</li>
      <li parentName="ul">{`Candidates: Retrieve & update custom fields`}</li>
      <li parentName="ul">{`Candidates: Multi-branch linking of a candidate`}</li>
      <li parentName="ul">{`Candidates: Delete a candidate`}</li>
      <li parentName="ul">{`Users: List and manage ESS users`}</li>
      <li parentName="ul">{`Users: List and manage enableHR users`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      